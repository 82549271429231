body {
  background-color: black;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover; /* This will cover the container without stretching */
}

.separator {
  height: 1px;
  width: 80%;
  background-color: white;
  margin: 20px 0; /* This will add space above and below the line */
}

.info-text {
  margin-bottom: 20px; /* This provides spacing between the text and the button */
  font-size: 18px;
}

/* ... Other styles ... */

.buttons-container {
  display: flex;
  justify-content: space-between;
  width: 300px; /* This width can be adjusted based on the button sizes and spacing you need */
  margin-bottom: 20px;
}

/* ... Other styles ... */

.download-button, .blog-button {
  padding: 12px 24px;
  border: 1px solid #D4D4D4; /* A subtle border */
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400; /* Regular weight */
  cursor: pointer;
  transition: all 0.3s ease-in-out; /* Smoother transition */
  text-transform: none; /* No uppercase to keep it smooth */
  color: #ffffff; /* Dark text color for elegance */
  background: transparent; /* Let's keep the background clean */
  outline: none;
}

.download-button {
  margin-right: 12px;
}

.download-button:hover, .blog-button:hover {
  border-color: #999; /* Slightly darker border on hover */
  background-color: #ffffff; /* Very light gray background on hover */
  color: #000000
}

.download-button:active, .blog-button:active {
  transform: scale(0.98); /* Slightly scale down on click */
  border-color: #666; /* Even darker on active click */
}

/* ... Other styles ... */

.email-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.fa-envelope {
  font-size: 20px;
  margin-right: 10px;
  color: #ffffff; /* Elegant blue color for the icon */
}

.email-text {
  font-size: 16px;
  color: #ffffff;
}

.email-text a {
  color: #ffffff; /* Matching color for email link */
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.email-text a:hover {
  text-decoration: underline;
}

/* ... Other styles ... */

.footer {
  position: absolute; /* Positions footer with respect to .container */
  bottom: 0;          /* Pins footer to bottom of .container */
  width: 100%;        /* Ensures footer takes full width */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Optional: Shadow for aesthetics */
}

.footer a {
  color: #ffffff; /* Dark color for icons */
  margin: 0 15px; /* Space between icons */
  font-size: 24px; /* Icon size */
  transition: all 0.3s ease-in-out;
  transform: scale(1); /* Starting scale */
}

.footer a:hover {
  color: #ffffff; /* Color transition on hover */
  transform: scale(1.6); /* Enlarge icon on hover */
}

